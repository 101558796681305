import { Injectable, signal } from '@angular/core';
import { SingleToast } from '../models/models';

@Injectable({ providedIn: 'root' })
export class SingleToastService {
  singleToast = signal(null);

  showToast(
    message: string,
    type: string = 'success',
    translationValues?: { [key: string]: string },
  ) {
    const toast: SingleToast = {
      message,
      type,
      translationValues,
    };

    if (this.singleToast() !== null) {
      this.singleToast.set(null);
    }

    // for animation
    setTimeout(() => {
      this.singleToast.set(toast);
    });
  }

  closeToast() {
    this.singleToast.set(null);
  }
}
