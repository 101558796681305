<nav
  mat-tab-nav-bar
  mat-stretch-tabs="false"
  [disableRipple]="true"
  [tabPanel]="tabPanel"
  #tabs
>
  @for (nav of navigation; track nav) {
    <a
      mat-tab-link
      class="tab"
      [attr.data-cy]="nav.name + '-link'"
      [routerLink]="nav.link"
      [active]="activeUrl === nav.link"
    >
      {{ 'OrganizationProfile.TabName.' + nav.name | translate }}
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel class="fxflex"> </mat-tab-nav-panel>
