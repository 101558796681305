import { createSelector } from '@ngrx/store';
import {
  apiSettingsTab,
  getDefaultRoute,
  getPagesForRole,
  hasAccessToPromptEngineer,
  organizationTabs,
} from '../../sources/rio-pages';
import { organizationsFeature } from '../organizations/organizations.reducer';
import { userFeature } from './user.reducer';

export const selectUserLanguageToChangeTo = createSelector(
  userFeature.selectUiLanguage,
  currentCode => (currentCode === 'de' ? 'en' : 'de'),
);

export const selectUserId = createSelector(
  userFeature.selectDetails,
  details => details.id,
);
export const selectCurrentMembership = createSelector(
  userFeature.selectMemberships,
  userFeature.selectCurrentOrganizationId,
  (memberships, selectedId) =>
    memberships.find(membership => membership.organizationId === selectedId),
);

export const selectUserRole = createSelector(
  userFeature.selectKeycloakRole,
  selectCurrentMembership,
  (keycloakRole, membership) => keycloakRole || membership?.userRole,
);

export const selectHasUserPromptEngineerRole = createSelector(
  selectUserRole,
  role => hasAccessToPromptEngineer(role),
);

export const selectUserRoutes = createSelector(selectUserRole, role =>
  getPagesForRole(role),
);
export const selectUserDefaultRoute = createSelector(selectUserRole, role =>
  getDefaultRoute(role),
);

export const selectIsAdmin = createSelector(
  selectUserRole,
  role => role === 'admin',
);

export const selectIsSuperAdmin = createSelector(
  selectUserRole,
  role => role === 'super_admin',
);

export const selectIsSupport = createSelector(
  selectUserRole,
  role => role === 'support',
);

export const selectIsUserSuperAdminOrSupport = createSelector(
  selectIsSuperAdmin,
  selectIsSupport,
  (isSuperAdmin, isSupport) => isSuperAdmin || isSupport,
);

export const selectIsUserAdminOrSupport = createSelector(
  selectIsAdmin,
  selectIsSuperAdmin,
  selectIsSupport,
  (isAdmin, isSuperAdmin, isSupport) => isAdmin || isSuperAdmin || isSupport,
);

export const selectUserOrganizations = createSelector(
  userFeature.selectMemberships,
  userFeature.selectKeycloakRole,
  organizationsFeature.selectOrganizationsState,
  (memberships, keycloakRole, organizations) => {
    const allOrganizationIds = memberships.map(
      membership => membership.organizationId,
    );

    const hasAccessToAllOrganizations =
      keycloakRole === 'super_admin' || keycloakRole === 'support';

    return hasAccessToAllOrganizations
      ? organizations
      : organizations.filter(organization =>
          allOrganizationIds.includes(organization.id),
        );
  },
);

export const selectUserOrganizationTabs = createSelector(
  selectUserRole,
  selectUserRole => organizationTabs[selectUserRole] || [],
);

export const selectHasAccessToApiSettings = createSelector(
  selectUserOrganizationTabs,
  organizationTabs =>
    organizationTabs.find(tab => tab.name === apiSettingsTab.name),
);
