import {
  Directive,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: '[appSmoothHeight]',
  standalone: true,
})
export class SmoothHeightDirective implements OnChanges {
  element = inject(ElementRef);
  @Input({ required: true }) triggerOn: unknown;
  @Input() cancel = false;
  @HostBinding('@grow') grow: {
    value: boolean;
    params: { startHeight: number };
  };

  pulse: boolean;
  startHeight: number;

  updateAnimationValues() {
    this.startHeight = this.element.nativeElement.clientHeight;
    this.grow = {
      value: this.pulse,
      params: { startHeight: this.startHeight },
    };
  }

  ngOnChanges() {
    if (this.cancel) {
      return;
    }

    this.pulse = !this.pulse;
    this.updateAnimationValues();
  }
}
