import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { localStorageActions } from './local-storage.actions';

@Injectable()
export class LocalStorageEffects {
  setLocalStorageItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(localStorageActions.setLocalStorageItem),
        tap(({ key, value }) => {
          const payload =
            typeof value === 'string' ? value : JSON.stringify(value);

          localStorage.setItem(key, payload);
        }),
      ),
    { dispatch: false },
  );

  removeLocalStorageItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(localStorageActions.removeLocalStorageItem),
        tap(({ key }) => {
          localStorage.removeItem(key);
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions) {}
}
