import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RESOURCES } from '../../services/resources';
import { API_PREFIX } from '../../utils/injection-tokens';
import {
  Membership,
  MembershipsResponse,
  User,
  UserBackendBody,
  UserBackendResponse,
} from './models';

@Injectable({ providedIn: 'root' })
export class UserService {
  API_PREFIX = inject(API_PREFIX);
  http = inject(HttpClient);

  getMemberships(userId: string): Observable<Membership[]> {
    const url = `${this.API_PREFIX}${RESOURCES.USER.memberships(userId)}`;

    return this.http.get<MembershipsResponse>(url).pipe(
      map(response =>
        response.memberships.map(membership => ({
          id: membership.id,
          organizationId: membership.organization_id,
          userRole: membership.role,
        })),
      ),
    );
  }

  updateUser(
    firstName: string,
    lastName: string,
    userId: string,
  ): Observable<User> {
    const url = `${this.API_PREFIX}${RESOURCES.USER.user(userId)}`;

    const body: UserBackendBody = {
      first_name: firstName,
      last_name: lastName,
    };

    return this.http.patch<UserBackendResponse>(url, body).pipe(
      map(user => ({
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
      })),
    );
  }
}
