<div class="output-characters fxflex fxcentercenter">
  <span class="margin-r-10" data-cy="character-count">
    @if (charactersCount !== 0) {
      {{ charactersCount }}
      {{
        (charactersCount === 1 ? 'Rewrite.Character' : 'Rewrite.Characters')
          | translate
      }}
    }

    @if (text) {
      - {{ text | translate: { textLength } }}
    }
  </span>
  @if (showSpinner) {
    <mat-spinner data-cy="stream-spinner" diameter="20"></mat-spinner>
  }
</div>
