import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { forEach } from 'lodash';

@NgModule()
export class MatIconRegistryModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    // Add custom icons to the library to make it globally usable
    const icons = [
      {
        value: 'upload',
        path: '/assets/svg/upload.svg',
      },
      {
        value: 'copy',
        path: '/assets/svg/copy.svg',
      },
      {
        value: 'delete',
        path: '/assets/svg/delete.svg',
      },
      {
        value: 'reload',
        path: '/assets/svg/reload.svg',
      },
      {
        value: 'download',
        path: '/assets/svg/download.svg',
      },
      {
        value: 'check',
        path: '/assets/svg/check.svg',
      },
      {
        value: 'arrow-bottom',
        path: '/assets/svg/arrow-bottom.svg',
      },
      {
        value: 'arrow-top',
        path: '/assets/svg/arrow-top.svg',
      },
      {
        value: 'arrow-left',
        path: '/assets/svg/arrow-left.svg',
      },
      {
        value: 'close-small',
        path: '/assets/svg/close-small.svg',
      },
      {
        value: 'close',
        path: '/assets/svg/close.svg',
      },
      {
        value: 'edit',
        path: '/assets/svg/edit.svg',
      },
      {
        value: 'power',
        path: '/assets/svg/power.svg',
      },
      {
        value: 'chevron-down',
        path: '/assets/svg/chevron-down.svg',
      },
      {
        value: 'chevron-up',
        path: '/assets/svg/chevron-up.svg',
      },
      {
        value: 'check-simple',
        path: '/assets/svg/check-simple.svg',
      },
      {
        value: 'profile',
        path: '/assets/svg/profile.svg',
      },
      {
        value: 'add',
        path: '/assets/svg/add.svg',
      },
      {
        value: 'navigation-top',
        path: '/assets/svg/navigation-top.svg',
      },
      {
        value: 'navigation-bottom',
        path: '/assets/svg/navigation-bottom.svg',
      },
      {
        value: 'resend-email',
        path: '/assets/svg/resend-email.svg',
      },
    ];

    forEach(icons, ({ value, path }) => {
      this.matIconRegistry.addSvgIcon(
        value,
        this.domSanitizer.bypassSecurityTrustResourceUrl(path),
      );
    });
  }
}
