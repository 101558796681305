import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { dialogActions } from './dialog.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class DialogEffects {
  closeAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dialogActions.closeAll),
        tap(() => {
          this.matDialog.closeAll();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private matDialog: MatDialog,
  ) {}
}
