import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavLink } from '../../models/rio-pages';

@Component({
  selector: 'app-nav-links',
  standalone: true,
  imports: [RouterModule, MatTabsModule, TranslateModule],
  templateUrl: './nav-links.component.html',
  styleUrl: './nav-links.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavLinksComponent {
  @Input() navigation: NavLink[];
  @Input() activeUrl: string;
}
