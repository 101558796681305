import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SingleToast, StackedToast } from 'toasts';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.5) translateY(100px)' }),
        animate(
          '200ms',
          style({ opacity: 1, transform: 'scale(1) translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1) translateY(0)' }),
        animate(
          '200ms',
          style({ opacity: 0, transform: 'scale(0.5) translateY(100px)' }),
        ),
      ]),
    ]),
  ],
  imports: [NgClass, TranslateModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
  @HostBinding('@fadeInOut') fadeInOut = true;
  @Output() closeToast = new EventEmitter<SingleToast | StackedToast>();
  @Input() toast: SingleToast | StackedToast;

  _delay = 3000;
  _timer: number;

  close() {
    this.closeToast.emit(this.toast);
  }

  ngOnInit() {
    this._timer = window.setTimeout(() => {
      this.closeToast.emit(this.toast);
    }, this._delay);
  }

  ngOnDestroy() {
    if (this._timer) {
      window.clearTimeout(this._timer);
    }
  }
}
