<div class="dialog fxcolumn" data-cy="tutorial-dialog">
  <div class="fxflex fxendcenter">
    <app-icon-button icon="close" [outline]="false" (click)="close()" />
  </div>
  <span class="title" data-cy="tutorial-title">{{
    'TutorialDialog.Title' | translate
  }}</span>
  <span class="description" data-cy="tutorial-description"
    >{{ 'TutorialDialog.Description' | translate }}
  </span>
  <div class="fxflex fxendcenter">
    <app-button
      text="TutorialDialog.Confirm"
      [dataCy]="'confirm-tutorial'"
      (click)="close()"
    />
  </div>
</div>
