import * as tslib_1 from "tslib";
import { getGlobalObject, isThenable, normalize, SyncPromise } from '@sentry/utils';
import { Span } from './span';
/**
 * Holds additional event information. {@link Scope.applyToEvent} will be
 * called by the client before an event will be sent.
 */
var Scope = /** @class */function () {
  function Scope() {
    /** Flag if notifiying is happening. */
    this._notifyingListeners = false;
    /** Callback for client to receive scope changes. */
    this._scopeListeners = [];
    /** Callback list that will be called after {@link applyToEvent}. */
    this._eventProcessors = [];
    /** Array of breadcrumbs. */
    this._breadcrumbs = [];
    /** User */
    this._user = {};
    /** Tags */
    this._tags = {};
    /** Extra */
    this._extra = {};
    /** Contexts */
    this._context = {};
  }
  /**
   * Add internal on change listener. Used for sub SDKs that need to store the scope.
   * @hidden
   */
  Scope.prototype.addScopeListener = function (callback) {
    this._scopeListeners.push(callback);
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.addEventProcessor = function (callback) {
    this._eventProcessors.push(callback);
    return this;
  };
  /**
   * This will be called on every set call.
   */
  Scope.prototype._notifyScopeListeners = function () {
    var _this = this;
    if (!this._notifyingListeners) {
      this._notifyingListeners = true;
      setTimeout(function () {
        _this._scopeListeners.forEach(function (callback) {
          callback(_this);
        });
        _this._notifyingListeners = false;
      });
    }
  };
  /**
   * This will be called after {@link applyToEvent} is finished.
   */
  Scope.prototype._notifyEventProcessors = function (processors, event, hint, index) {
    var _this = this;
    if (index === void 0) {
      index = 0;
    }
    return new SyncPromise(function (resolve, reject) {
      var processor = processors[index];
      // tslint:disable-next-line:strict-type-predicates
      if (event === null || typeof processor !== 'function') {
        resolve(event);
      } else {
        var result = processor(tslib_1.__assign({}, event), hint);
        if (isThenable(result)) {
          result.then(function (final) {
            return _this._notifyEventProcessors(processors, final, hint, index + 1).then(resolve);
          }).then(null, reject);
        } else {
          _this._notifyEventProcessors(processors, result, hint, index + 1).then(resolve).then(null, reject);
        }
      }
    });
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setUser = function (user) {
    this._user = normalize(user);
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setTags = function (tags) {
    this._tags = tslib_1.__assign({}, this._tags, normalize(tags));
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setTag = function (key, value) {
    var _a;
    this._tags = tslib_1.__assign({}, this._tags, (_a = {}, _a[key] = normalize(value), _a));
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setExtras = function (extra) {
    this._extra = tslib_1.__assign({}, this._extra, normalize(extra));
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setExtra = function (key, extra) {
    var _a;
    this._extra = tslib_1.__assign({}, this._extra, (_a = {}, _a[key] = normalize(extra), _a));
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setFingerprint = function (fingerprint) {
    this._fingerprint = normalize(fingerprint);
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setLevel = function (level) {
    this._level = normalize(level);
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setTransaction = function (transaction) {
    this._transaction = transaction;
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setContext = function (name, context) {
    this._context[name] = context ? normalize(context) : undefined;
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.setSpan = function (span) {
    this._span = span;
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.startSpan = function (parentSpan) {
    var span = new Span();
    span.setParent(parentSpan);
    this.setSpan(span);
    return span;
  };
  /**
   * Internal getter for Span, used in Hub.
   * @hidden
   */
  Scope.prototype.getSpan = function () {
    return this._span;
  };
  /**
   * Inherit values from the parent scope.
   * @param scope to clone.
   */
  Scope.clone = function (scope) {
    var newScope = new Scope();
    if (scope) {
      newScope._breadcrumbs = tslib_1.__spread(scope._breadcrumbs);
      newScope._tags = tslib_1.__assign({}, scope._tags);
      newScope._extra = tslib_1.__assign({}, scope._extra);
      newScope._context = tslib_1.__assign({}, scope._context);
      newScope._user = scope._user;
      newScope._level = scope._level;
      newScope._span = scope._span;
      newScope._transaction = scope._transaction;
      newScope._fingerprint = scope._fingerprint;
      newScope._eventProcessors = tslib_1.__spread(scope._eventProcessors);
    }
    return newScope;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.clear = function () {
    this._breadcrumbs = [];
    this._tags = {};
    this._extra = {};
    this._user = {};
    this._context = {};
    this._level = undefined;
    this._transaction = undefined;
    this._fingerprint = undefined;
    this._span = undefined;
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.addBreadcrumb = function (breadcrumb, maxBreadcrumbs) {
    var timestamp = new Date().getTime() / 1000;
    var mergedBreadcrumb = tslib_1.__assign({
      timestamp: timestamp
    }, breadcrumb);
    this._breadcrumbs = maxBreadcrumbs !== undefined && maxBreadcrumbs >= 0 ? tslib_1.__spread(this._breadcrumbs, [normalize(mergedBreadcrumb)]).slice(-maxBreadcrumbs) : tslib_1.__spread(this._breadcrumbs, [normalize(mergedBreadcrumb)]);
    this._notifyScopeListeners();
    return this;
  };
  /**
   * @inheritDoc
   */
  Scope.prototype.clearBreadcrumbs = function () {
    this._breadcrumbs = [];
    this._notifyScopeListeners();
    return this;
  };
  /**
   * Applies fingerprint from the scope to the event if there's one,
   * uses message if there's one instead or get rid of empty fingerprint
   */
  Scope.prototype._applyFingerprint = function (event) {
    // Make sure it's an array first and we actually have something in place
    event.fingerprint = event.fingerprint ? Array.isArray(event.fingerprint) ? event.fingerprint : [event.fingerprint] : [];
    // If we have something on the scope, then merge it with event
    if (this._fingerprint) {
      event.fingerprint = event.fingerprint.concat(this._fingerprint);
    }
    // If we have no data at all, remove empty array default
    if (event.fingerprint && !event.fingerprint.length) {
      delete event.fingerprint;
    }
  };
  /**
   * Applies the current context and fingerprint to the event.
   * Note that breadcrumbs will be added by the client.
   * Also if the event has already breadcrumbs on it, we do not merge them.
   * @param event Event
   * @param hint May contain additional informartion about the original exception.
   * @hidden
   */
  Scope.prototype.applyToEvent = function (event, hint) {
    if (this._extra && Object.keys(this._extra).length) {
      event.extra = tslib_1.__assign({}, this._extra, event.extra);
    }
    if (this._tags && Object.keys(this._tags).length) {
      event.tags = tslib_1.__assign({}, this._tags, event.tags);
    }
    if (this._user && Object.keys(this._user).length) {
      event.user = tslib_1.__assign({}, this._user, event.user);
    }
    if (this._context && Object.keys(this._context).length) {
      event.contexts = tslib_1.__assign({}, this._context, event.contexts);
    }
    if (this._level) {
      event.level = this._level;
    }
    if (this._transaction) {
      event.transaction = this._transaction;
    }
    if (this._span) {
      event.contexts = event.contexts || {};
      event.contexts.trace = this._span;
    }
    this._applyFingerprint(event);
    event.breadcrumbs = tslib_1.__spread(event.breadcrumbs || [], this._breadcrumbs);
    event.breadcrumbs = event.breadcrumbs.length > 0 ? event.breadcrumbs : undefined;
    return this._notifyEventProcessors(tslib_1.__spread(getGlobalEventProcessors(), this._eventProcessors), event, hint);
  };
  return Scope;
}();
export { Scope };
/**
 * Retruns the global event processors.
 */
function getGlobalEventProcessors() {
  var global = getGlobalObject();
  global.__SENTRY__ = global.__SENTRY__ || {};
  global.__SENTRY__.globalEventProcessors = global.__SENTRY__.globalEventProcessors || [];
  return global.__SENTRY__.globalEventProcessors;
}
/**
 * Add a EventProcessor to be kept globally.
 * @param callback EventProcessor to add
 */
export function addGlobalEventProcessor(callback) {
  getGlobalEventProcessors().push(callback);
}
