import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Organization } from './model';

export const organizationsActions = createActionGroup({
  source: 'Organizations',
  events: {
    'Load Organizations': emptyProps(),
    'Load Organizations Success': props<{ organizations: Organization[] }>(),
    'Load Organizations Failure': emptyProps(),

    'Change Organization Name': props<{ name: string }>(),
    'Change Organization Name Success': props<{
      organizationId: string;
      name: string;
    }>(),
    'Change Organization Name Failure': emptyProps(),

    'Increase Organization Member Counter': props<{ organizationId: string }>(),
    'Decrease Organization Member Counter': props<{ organizationId: string }>(),

    CreateOrganization: props<{ name: string; isInternal: boolean }>(),
    CreateOrganizationSuccess: props<{ organizationId: string }>(),
    CreateOrganizationFailure: emptyProps(),
  },
});
