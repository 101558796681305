import { AbstractControl, ValidationErrors } from '@angular/forms';

export function WhiteSpacesValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const value = control.value || '';
  const isOnlyWhitespace = value.length && value.trim().length === 0;
  const startOrEndWithWhiteSpace = value.trim().length != value.length;

  return isOnlyWhitespace || startOrEndWithWhiteSpace
    ? { whiteSpaces: true }
    : null;
}
