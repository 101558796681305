import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RESOURCES } from '../../services/resources';
import { API_PREFIX } from '../../utils/injection-tokens';
import { Prompt, PromptsResponse } from './models';

@Injectable()
export class PromptsService {
  API_PREFIX = inject(API_PREFIX);
  httpClient = inject(HttpClient);

  getPrompts(organizationId: string): Observable<Prompt[]> {
    const url = `${this.API_PREFIX}${RESOURCES.PROMPTS.prompts(
      organizationId,
    )}`;

    return this.httpClient.get<PromptsResponse>(url).pipe(
      map(response =>
        response.prompts.map(prompt => ({
          id: prompt.id,
          name: prompt.name,
          categories: prompt.categories,
          activatedVersion: prompt.activated_version,
          isDefault: prompt.is_default_prompt,
          textLength: prompt.text_length,
          allowTextLengthModification: prompt.allow_text_length_modification,
          quotePreservation: prompt.quote_preservation,
        })),
      ),
    );
  }
}
