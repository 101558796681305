import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { TrackingEventType } from '../models/tracking-events';

@Injectable({ providedIn: 'root' })
export class TrackingService {
  init(mixpanelKey: string): void {
    mixpanel.init(mixpanelKey);
  }

  identify(userId: string): void {
    mixpanel.identify(userId);
  }

  track(event: TrackingEventType, payload = {}): void {
    mixpanel.track(event, payload);
  }

  setOrganization(organizationId: string, isInternal: boolean): void {
    if (organizationId) {
      mixpanel.set_group('organization_id', organizationId);
      mixpanel.set_group('organization_internal', isInternal.toString());
    }
  }

  resetUser() {
    mixpanel.reset();
  }
}
