<button class="upload-button icon-button border">
  <mat-icon svgIcon="upload"></mat-icon>
  <input
    #uploadElement
    class="upload-input"
    type="file"
    ng2FileSelect
    [uploader]="fileUploader"
    [accept]="accept"
    (blur)="onBlur($event)"
  />
</button>
