import * as tslib_1 from "tslib";
import { consoleSandbox, dynamicRequire, getGlobalObject, isNodeEnv, logger, uuid4 } from '@sentry/utils';
import { Scope } from './scope';
/**
 * API compatibility version of this hub.
 *
 * WARNING: This number should only be incresed when the global interface
 * changes a and new methods are introduced.
 *
 * @hidden
 */
export var API_VERSION = 3;
/**
 * Default maximum number of breadcrumbs added to an event. Can be overwritten
 * with {@link Options.maxBreadcrumbs}.
 */
var DEFAULT_BREADCRUMBS = 30;
/**
 * Absolute maximum number of breadcrumbs added to an event. The
 * `maxBreadcrumbs` option cannot be higher than this value.
 */
var MAX_BREADCRUMBS = 100;
/**
 * @inheritDoc
 */
var Hub = /** @class */function () {
  /**
   * Creates a new instance of the hub, will push one {@link Layer} into the
   * internal stack on creation.
   *
   * @param client bound to the hub.
   * @param scope bound to the hub.
   * @param version number, higher number means higher priority.
   */
  function Hub(client, scope, _version) {
    if (scope === void 0) {
      scope = new Scope();
    }
    if (_version === void 0) {
      _version = API_VERSION;
    }
    this._version = _version;
    /** Is a {@link Layer}[] containing the client and scope */
    this._stack = [];
    this._stack.push({
      client: client,
      scope: scope
    });
  }
  /**
   * Internal helper function to call a method on the top client if it exists.
   *
   * @param method The method to call on the client.
   * @param args Arguments to pass to the client function.
   */
  Hub.prototype._invokeClient = function (method) {
    var _a;
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    var top = this.getStackTop();
    if (top && top.client && top.client[method]) {
      (_a = top.client)[method].apply(_a, tslib_1.__spread(args, [top.scope]));
    }
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.isOlderThan = function (version) {
    return this._version < version;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.bindClient = function (client) {
    var top = this.getStackTop();
    top.client = client;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.pushScope = function () {
    // We want to clone the content of prev scope
    var stack = this.getStack();
    var parentScope = stack.length > 0 ? stack[stack.length - 1].scope : undefined;
    var scope = Scope.clone(parentScope);
    this.getStack().push({
      client: this.getClient(),
      scope: scope
    });
    return scope;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.popScope = function () {
    return this.getStack().pop() !== undefined;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.withScope = function (callback) {
    var scope = this.pushScope();
    try {
      callback(scope);
    } finally {
      this.popScope();
    }
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.getClient = function () {
    return this.getStackTop().client;
  };
  /** Returns the scope of the top stack. */
  Hub.prototype.getScope = function () {
    return this.getStackTop().scope;
  };
  /** Returns the scope stack for domains or the process. */
  Hub.prototype.getStack = function () {
    return this._stack;
  };
  /** Returns the topmost scope layer in the order domain > local > process. */
  Hub.prototype.getStackTop = function () {
    return this._stack[this._stack.length - 1];
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.captureException = function (exception, hint) {
    var eventId = this._lastEventId = uuid4();
    var finalHint = hint;
    // If there's no explicit hint provided, mimick the same thing that would happen
    // in the minimal itself to create a consistent behavior.
    // We don't do this in the client, as it's the lowest level API, and doing this,
    // would prevent user from having full control over direct calls.
    if (!hint) {
      var syntheticException = void 0;
      try {
        throw new Error('Sentry syntheticException');
      } catch (exception) {
        syntheticException = exception;
      }
      finalHint = {
        originalException: exception,
        syntheticException: syntheticException
      };
    }
    this._invokeClient('captureException', exception, tslib_1.__assign({}, finalHint, {
      event_id: eventId
    }));
    return eventId;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.captureMessage = function (message, level, hint) {
    var eventId = this._lastEventId = uuid4();
    var finalHint = hint;
    // If there's no explicit hint provided, mimick the same thing that would happen
    // in the minimal itself to create a consistent behavior.
    // We don't do this in the client, as it's the lowest level API, and doing this,
    // would prevent user from having full control over direct calls.
    if (!hint) {
      var syntheticException = void 0;
      try {
        throw new Error(message);
      } catch (exception) {
        syntheticException = exception;
      }
      finalHint = {
        originalException: message,
        syntheticException: syntheticException
      };
    }
    this._invokeClient('captureMessage', message, level, tslib_1.__assign({}, finalHint, {
      event_id: eventId
    }));
    return eventId;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.captureEvent = function (event, hint) {
    var eventId = this._lastEventId = uuid4();
    this._invokeClient('captureEvent', event, tslib_1.__assign({}, hint, {
      event_id: eventId
    }));
    return eventId;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.lastEventId = function () {
    return this._lastEventId;
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.addBreadcrumb = function (breadcrumb, hint) {
    var top = this.getStackTop();
    if (!top.scope || !top.client) {
      return;
    }
    var _a = top.client.getOptions && top.client.getOptions() || {},
      _b = _a.beforeBreadcrumb,
      beforeBreadcrumb = _b === void 0 ? null : _b,
      _c = _a.maxBreadcrumbs,
      maxBreadcrumbs = _c === void 0 ? DEFAULT_BREADCRUMBS : _c;
    if (maxBreadcrumbs <= 0) {
      return;
    }
    var timestamp = new Date().getTime() / 1000;
    var mergedBreadcrumb = tslib_1.__assign({
      timestamp: timestamp
    }, breadcrumb);
    var finalBreadcrumb = beforeBreadcrumb ? consoleSandbox(function () {
      return beforeBreadcrumb(mergedBreadcrumb, hint);
    }) : mergedBreadcrumb;
    if (finalBreadcrumb === null) {
      return;
    }
    top.scope.addBreadcrumb(finalBreadcrumb, Math.min(maxBreadcrumbs, MAX_BREADCRUMBS));
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setUser = function (user) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setUser(user);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setTags = function (tags) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setTags(tags);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setExtras = function (extras) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setExtras(extras);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setTag = function (key, value) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setTag(key, value);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setExtra = function (key, extra) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setExtra(key, extra);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.setContext = function (name, context) {
    var top = this.getStackTop();
    if (!top.scope) {
      return;
    }
    top.scope.setContext(name, context);
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.configureScope = function (callback) {
    var top = this.getStackTop();
    if (top.scope && top.client) {
      callback(top.scope);
    }
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.run = function (callback) {
    var oldHub = makeMain(this);
    try {
      callback(this);
    } finally {
      makeMain(oldHub);
    }
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.getIntegration = function (integration) {
    var client = this.getClient();
    if (!client) {
      return null;
    }
    try {
      return client.getIntegration(integration);
    } catch (_oO) {
      logger.warn("Cannot retrieve integration " + integration.id + " from the current Hub");
      return null;
    }
  };
  /**
   * @inheritDoc
   */
  Hub.prototype.traceHeaders = function () {
    var top = this.getStackTop();
    if (top.scope && top.client) {
      var span = top.scope.getSpan();
      if (span) {
        return {
          'sentry-trace': span.toTraceparent()
        };
      }
    }
    return {};
  };
  return Hub;
}();
export { Hub };
/** Returns the global shim registry. */
export function getMainCarrier() {
  var carrier = getGlobalObject();
  carrier.__SENTRY__ = carrier.__SENTRY__ || {
    hub: undefined
  };
  return carrier;
}
/**
 * Replaces the current main hub with the passed one on the global object
 *
 * @returns The old replaced hub
 */
export function makeMain(hub) {
  var registry = getMainCarrier();
  var oldHub = getHubFromCarrier(registry);
  setHubOnCarrier(registry, hub);
  return oldHub;
}
/**
 * Returns the default hub instance.
 *
 * If a hub is already registered in the global carrier but this module
 * contains a more recent version, it replaces the registered version.
 * Otherwise, the currently registered hub will be returned.
 */
export function getCurrentHub() {
  // Get main carrier (global for every environment)
  var registry = getMainCarrier();
  // If there's no hub, or its an old API, assign a new one
  if (!hasHubOnCarrier(registry) || getHubFromCarrier(registry).isOlderThan(API_VERSION)) {
    setHubOnCarrier(registry, new Hub());
  }
  // Prefer domains over global if they are there (applicable only to Node environment)
  if (isNodeEnv()) {
    return getHubFromActiveDomain(registry);
  }
  // Return hub that lives on a global object
  return getHubFromCarrier(registry);
}
/**
 * Try to read the hub from an active domain, fallback to the registry if one doesnt exist
 * @returns discovered hub
 */
function getHubFromActiveDomain(registry) {
  try {
    // We need to use `dynamicRequire` because `require` on it's own will be optimized by webpack.
    // We do not want this to happen, we need to try to `require` the domain node module and fail if we are in browser
    // for example so we do not have to shim it and use `getCurrentHub` universally.
    var domain = dynamicRequire(module, 'domain');
    var activeDomain = domain.active;
    // If there no active domain, just return global hub
    if (!activeDomain) {
      return getHubFromCarrier(registry);
    }
    // If there's no hub on current domain, or its an old API, assign a new one
    if (!hasHubOnCarrier(activeDomain) || getHubFromCarrier(activeDomain).isOlderThan(API_VERSION)) {
      var registryHubTopStack = getHubFromCarrier(registry).getStackTop();
      setHubOnCarrier(activeDomain, new Hub(registryHubTopStack.client, Scope.clone(registryHubTopStack.scope)));
    }
    // Return hub that lives on a domain
    return getHubFromCarrier(activeDomain);
  } catch (_Oo) {
    // Return hub that lives on a global object
    return getHubFromCarrier(registry);
  }
}
/**
 * This will tell whether a carrier has a hub on it or not
 * @param carrier object
 */
function hasHubOnCarrier(carrier) {
  if (carrier && carrier.__SENTRY__ && carrier.__SENTRY__.hub) {
    return true;
  }
  return false;
}
/**
 * This will create a new {@link Hub} and add to the passed object on
 * __SENTRY__.hub.
 * @param carrier object
 * @hidden
 */
export function getHubFromCarrier(carrier) {
  if (carrier && carrier.__SENTRY__ && carrier.__SENTRY__.hub) {
    return carrier.__SENTRY__.hub;
  }
  carrier.__SENTRY__ = carrier.__SENTRY__ || {};
  carrier.__SENTRY__.hub = new Hub();
  return carrier.__SENTRY__.hub;
}
/**
 * This will set passed {@link Hub} on the passed object's __SENTRY__.hub attribute
 * @param carrier object
 * @param hub Hub
 */
export function setHubOnCarrier(carrier, hub) {
  if (!carrier) {
    return false;
  }
  carrier.__SENTRY__ = carrier.__SENTRY__ || {};
  carrier.__SENTRY__.hub = hub;
  return true;
}
