<div
  class="chip fxspacecenter"
  [ngClass]="type"
  [class.clickable]="clickable"
  [class.large]="large"
  [class.disabled]="disabled"
  [class.truncate]="truncate"
  [style.max-width.px]="maxWidth"
  data-cy="chip"
>
  @if (icon) {
    <mat-icon class="icon">{{ icon }}</mat-icon>
  }

  <span
    [appDisableTooltip]="text"
    [matTooltip]="text"
    [truncate]="truncate"
    [attr.data-cy]="text + '-chip'"
    >{{ text | translate }}</span
  >
</div>
