import { createActionGroup, props } from '@ngrx/store';

export const toastActions = createActionGroup({
  source: 'toast',
  events: {
    'Show Single Toast': props<{
      text: string;
      toastType?: string;
      translationValues?: { [key: string]: string };
    }>(),
    'Show Stacked Toast': props<{
      text: string;
      translationValues?: { [key: string]: string };
    }>(),
  },
});
