import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SystemVersionEntry } from 'rio-models';
import { ButtonComponent } from '../buttons/button/button.component';
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';

@Component({
  selector: 'app-system-versions-dialog',
  standalone: true,
  imports: [ButtonComponent, DialogWrapperComponent, TranslateModule],
  templateUrl: './system-versions-dialog.component.html',
  styleUrl: './system-versions-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemVersionsDialogComponent {
  dialogRef = inject(MatDialogRef<SystemVersionsDialogComponent>);

  versions = inject<SystemVersionEntry[]>(MAT_DIALOG_DATA);

  close() {
    this.dialogRef.close();
  }
}
