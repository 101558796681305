<app-dialog-wrapper>
  <ng-container title>
    {{ 'SystemVersions.Title' | translate }}
  </ng-container>

  <ng-container content>
    @for (version of versions; track $index) {
      <div class="version">
        <span>{{ version.name }}</span
        >:
        <span>{{ version.value }}</span>
      </div>
    }
  </ng-container>

  <ng-container actions>
    <app-button
      type="primary"
      text="SystemVersions.Confirm"
      (click)="close()"
    />
  </ng-container>
</app-dialog-wrapper>
