<div class="stacked-toasts">
  @for (toast of toasts(); track toast.id) {
    <app-toast [toast]="toast" (closeToast)="closeStackedToast($event)" />
  }
</div>

<div class="single-toast">
  @if (toast()) {
    <app-toast [toast]="toast()" (closeToast)="closeSingleToast()" />
  }
</div>
