export const RESOURCES = {
  REWRITE: {
    upload: (organizationId: string) =>
      `/organizations/${organizationId}/upload`,
    rewrite: (organizationId: string) =>
      `/organizations/${organizationId}/rewrite`,
    rewriteStream: (organizationId: string) =>
      `/organizations/${organizationId}/rewrite-stream`,
    download: (organizationId: string) =>
      `/organizations/${organizationId}/download`,
  },
  CATEGORIES: {
    categories: (organizationId: string) =>
      `/organizations/${organizationId}/categories`,
    category: (organizationId: string, categoryId: string) =>
      `/organizations/${organizationId}/categories/${categoryId}`,
    categoryCheck: (categoryId: string, organizationId: string) =>
      `/organizations/${organizationId}/categories/${categoryId}/check`,
  },
  PROMPTS: {
    prompts: (organizationId: string) =>
      `/organizations/${organizationId}/prompts`,
    prompt: (organizationId: string, promptId: string) =>
      `/organizations/${organizationId}/prompts/${promptId}`,
  },
  VERSIONS: {
    versions: (organizationId: string, promptId: string) =>
      `/organizations/${organizationId}/prompts/${promptId}/versions`,
    version: (organizationId: string, promptId: string, versionId: string) =>
      `/organizations/${organizationId}/prompts/${promptId}/versions/${versionId}`,
  },
  ORGANIZATIONS: {
    organizations: '/organizations',
    organization: (organizationId: string) =>
      `/organizations/${organizationId}`,
  },
  API_CONSUMERS: {
    apiSettings: (organizationId: string) =>
      `/organizations/${organizationId}/api-consumers`,
    apiConsumer: (organizationId: string, apiConsumerId: string) =>
      `/organizations/${organizationId}/api-consumers/${apiConsumerId}`,
  },
  MEMBERS: {
    members: (organizationId: string) =>
      `/organizations/${organizationId}/members`,
    member: (organizationId: string, memberId: string) =>
      `/organizations/${organizationId}/members/${memberId}`,
    membership: (organizationId: string, membershipId: string) =>
      `/organizations/${organizationId}/memberships/${membershipId}`,
    memberships: (organizationId: string) =>
      `/organizations/${organizationId}/memberships`,
  },
  USER: {
    memberships: (userId: string) => `/users/${userId}/memberships`,
    user: (userId: string) => `/users/${userId}`,
    resendInvitationEmail: (userId: string) =>
      `/users/${userId}/resend-invitation-email`,
  },
  API: {
    rewrite: '/v1/rewrite',
    prompts: '/v1/prompts',
  },
  SYSTEM: {
    systemVersion: () => '/_system/version',
  },
};
