import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DisableTooltipDirective } from 'ui-core';
@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    DisableTooltipDirective,
    TranslateModule,
  ],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input({ required: true }) text: string;
  @Input() type: ChipType = 'primary';
  @Input() icon: string;
  @Input() clickable = true;
  @Input() large = false;
  @Input() disabled = false;
  @Input() maxWidth: number;
  @Input() truncate: boolean;
}

export type ChipType =
  | 'primary'
  | 'light-primary'
  | 'black'
  | 'outlined'
  | 'violet'
  | 'light-violet'
  | 'dark-violet';
