<div class="container fxflex fxcentercenter fxcolumn">
  <div
    id="tutorial-thread-selector"
    data-cy="thread-menu"
    class="fxflex fxcolumn fxstartcenter"
  >
    <app-icon-button
      class="arrow"
      data-cy="thread-up"
      icon="navigation-top"
      [outline]="false"
      [disabled]="upDisabled()"
      (click)="navigateUp()"
    />
    @for (thread of threads(); track thread.id; let index = $index) {
      <div
        class="dot-wrapper"
        [attr.data-cy]="'thread-' + index"
        (click)="onDotUsed(index)"
        [class.active]="thread.active"
      >
        <div data-cy="thread-dot" class="dot"></div>
      </div>
    }
    <app-icon-button
      class="arrow"
      data-cy="thread-down"
      icon="navigation-bottom"
      [outline]="false"
      [disabled]="downDisabled()"
      (click)="navigateDown()"
    />
  </div>
</div>
