import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogData, InputDialogData, simpleDialogConfig } from 'rio-models';
import { InputDialogComponent, SimpleDialogComponent } from 'rio-ui';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  matDialog = inject(MatDialog);

  openConfirmDialog$(data: DialogData): Observable<boolean> {
    return this.openDialog$(data);
  }

  openInputDialog$(data: InputDialogData): Observable<string> {
    return this.openInput(data);
  }

  private openDialog$(data: DialogData): Observable<boolean> {
    const dialogRef = this.matDialog.open(SimpleDialogComponent, {
      ...simpleDialogConfig,
      data,
    });

    return dialogRef.afterClosed().pipe(take(1));
  }

  private openInput(data: InputDialogData): Observable<string> {
    const dialogRef = this.matDialog.open(InputDialogComponent, {
      ...simpleDialogConfig,
      data,
    });

    return dialogRef.afterClosed().pipe(take(1));
  }
}
