import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitArray',
  standalone: true,
})
export class SplitArrayPipe implements PipeTransform {
  transform(array: string[], sep = ', '): string {
    return array.join(sep);
  }
}
