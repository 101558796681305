<app-dialog-wrapper>
  <ng-container title>
    {{ data.title | translate }}
  </ng-container>

  <ng-container content>
    <span class="form-label" [class.warn]="name.invalid && name.touched">
      {{ data.description | translate }}
    </span>
    <mat-form-field
      appearance="outline"
      class="input-hint border radius-6 big-font full-width margin-b-xl"
    >
      <input
        matInput
        type="text"
        maxlength="20"
        data-cy="dialog-input"
        [formControl]="name"
        [class.input-value]="name?.value"
        [placeholder]="'InputDialog.Placeholder' | translate"
      />
      <span matSuffix>
        {{ 20 - (name.value?.length || 0) }}
        {{ 'Rewrite.Characters left' | translate }}
      </span>

      @if (name.hasError('minlength')) {
        <mat-error>{{ 'Validators.MinimumLength' | translate }}</mat-error>
      }

      @if (name.hasError('nameExist')) {
        <mat-error>{{ 'Validators.NameExists' | translate }}</mat-error>
      }

      @if (name.hasError('required')) {
        <mat-error>{{ 'Validators.Required' | translate }}</mat-error>
      }

      @if (name.hasError('onlyWhitespacePresent')) {
        <mat-error>{{ 'Validators.WhiteSpace' | translate }}</mat-error>
      }
    </mat-form-field>
  </ng-container>

  <ng-container actions>
    <app-button
      dataCy="cancel-dialog"
      type="secondary"
      class="margin-r-s"
      [text]="'InputDialog.Cancel' | translate"
      (click)="cancel()"
    />
    <app-button
      dataCy="confirm-dialog"
      type="primary"
      [disabled]="name.invalid"
      [text]="data.submitButtonText"
      (click)="confirm()"
    />
  </ng-container>
</app-dialog-wrapper>
