import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const systemActions = createActionGroup({
  source: 'System',
  events: {
    headerLogoClicked: props<{
      openSystemVersionDialogWhenUserPressesCtrlOrMetaKey: boolean;
    }>(),
    showVersionsDialog: emptyProps(),
    showVersionsDialogSuccess: emptyProps(),
    showVersionsDialogFailure: emptyProps(),
  },
});
