import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Prompt } from './models';

export const promptsActions = createActionGroup({
  source: 'Prompts',
  events: {
    'Check Load Prompts': emptyProps(),
    'Load Prompts': emptyProps(),
    'Load Prompts Success': props<{ prompts: Prompt[] }>(),
    'Load Prompts Failure': emptyProps(),

    'Delete Prompt': props<{ promptId: string }>(),
    'Delete Prompt Success': props<{ promptId: string }>(),
    'Delete Prompt Failure': emptyProps(),

    'Clear All Prompts': emptyProps(),
  },
});
