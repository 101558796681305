import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { KeycloakRole, Membership, UiLanguage, User } from './models';

export const userActions = createActionGroup({
  source: 'User',
  events: {
    'Load User': props<{ user: User }>(),
    'Load Language': emptyProps(),
    'Load Language Success': props<{ uiLanguage: UiLanguage }>(),
    'Load Language Failure': emptyProps(),

    'Change Language': emptyProps(),
    'Change Language Success': props<{ uiLanguage: UiLanguage }>(),
    'Change Language Failure': emptyProps(),

    Logout: emptyProps(),
    'Logout Success': emptyProps(),
    'Logout Failure': emptyProps(),

    'Start App': props<{ user: User }>(),

    'Load Memberships': emptyProps(),
    'Set Keycloak Role': props<{ keycloakRole: KeycloakRole }>(),
    'Set Memberships': props<{ memberships: Membership[] }>(),
    'Select Organization': props<{ organizationId: string }>(),
    'Load Memberships Failure': emptyProps(),
  },
});
