import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  saveFile(fileName: string, file: Blob): void {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', fileName);
    downloadLink.setAttribute('href', URL.createObjectURL(file));
    downloadLink.click();
  }
}
