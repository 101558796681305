import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

/**
 * Factory function, only used for initialization in the app.module.ts
 */
export function configProvider(configService: ConfigService) {
  return () => configService.load();
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  // PATH ON SERVER
  private readonly configUrl = 'assets/config.json';

  externalApiHost: string;

  constructor(private readonly http: HttpClient) {}

  /**
   * Promise is justified. It is required for the factory
   * of the initial app in the app.module.ts
   */
  load() {
    return this.http.get(this.configUrl).pipe(
      tap((config: { externalApiHost: string }) => {
        this.externalApiHost = config.externalApiHost;
      }),
    );
  }
}
