import { createSelector } from '@ngrx/store';
import { Prompt } from './models';
import { promptsFeature } from './prompts.reducer';

export const selectActivePrompts = createSelector(
  promptsFeature.selectPromptsState,
  (prompts): Prompt[] => prompts.filter(prompt => prompt.activatedVersion),
);

export const selectPromptById = (promptId: string) =>
  createSelector(selectActivePrompts, prompts =>
    prompts.find(prompt => prompt.id === promptId),
  );

export const selectPromptsIds = createSelector(
  selectActivePrompts,
  (prompts): string[] => prompts.map(prompt => prompt.id),
);

export const selectIsPromptDefaultById = (promptId: string) =>
  createSelector(selectPromptById(promptId), prompt => prompt?.isDefault);
