import { createActionGroup, props } from '@ngrx/store';
import { TrackingEventType } from '../models/tracking-events';

export const trackingActions = createActionGroup({
  source: 'Tracking',
  events: {
    Track: props<{
      trackingEvent: TrackingEventType;
      payload?: object;
    }>()
  },
});
