import { MatDialogConfig } from '@angular/material/dialog';

export const complexDialogConfig: MatDialogConfig = {
  autoFocus: 'first-tabbable',
  width: '600px',
  disableClose: true,
};

export const simpleDialogConfig: MatDialogConfig = {
  autoFocus: false,
  width: '625px',
  restoreFocus: false,
  disableClose: true,
  panelClass: 'rio-dialog',
};

export interface InputDialogData {
  title: string;
  description: string;
  submitButtonText: string;
  values: string[];
  inputValue?: string;
}

export interface DialogData {
  title: string;
  description: string;
  cancelButtonText?: string;
  submitButtonText: string;
  values?: { [key: string]: string };
}
