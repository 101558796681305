import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectHasAccessToApiSettings } from '../+state/user/user.selectors';

export const hasAccessToApiSettingsGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store
    .select(selectHasAccessToApiSettings)
    .pipe(map(hasAccess => (hasAccess ? true : router.createUrlTree(['/']))));
};
