import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-output-footer',
  standalone: true,
  imports: [TranslateModule, MatProgressSpinnerModule],
  templateUrl: './output-footer.component.html',
  styleUrl: './output-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputFooterComponent implements OnChanges {
  @Input({ required: true }) charactersCount: number;
  @Input({ required: true }) textLength: number;
  @Input({ required: true }) quotePreservation: boolean;
  @Input({ required: true }) showSpinner: boolean;
  @Input({ required: false }) hasManualChanges: boolean;

  text: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasManualChanges) {
      this.text = this.getText();
    }
  }

  getText(): string {
    let text;

    if (this.hasManualChanges) {
      return;
    }

    if (this.textLength) {
      text = this.quotePreservation
        ? 'Rewrite.Optimised for x characters and quote preservation'
        : 'Rewrite.Optimised for x characters';

      return text;
    }

    if (this.quotePreservation) {
      text = 'Rewrite.Optimised for quote preservation';

      return text;
    }
  }
}
