import { Injectable, signal } from '@angular/core';
import { StackedToast } from '../models/models';

@Injectable({ providedIn: 'root' })
export class StackedToastService {
  stackedToasts = signal<StackedToast[]>([]);

  id = 1;

  private createNewToast(
    message: string,
    translationValues?: { [key: string]: string },
  ): StackedToast {
    return {
      id: this.id++,
      message,
      type: 'error',
      translationValues,
    };
  }

  showToast(message: string, translationValues?: { [key: string]: string }) {
    const toast = this.createNewToast(message, translationValues);

    this.stackedToasts.update(values => [...values, toast]);
  }

  closeToast(toast: StackedToast) {
    this.stackedToasts.update(values =>
      values.filter(toastItem => toastItem.id !== toast.id),
    );
  }
}
