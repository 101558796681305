import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RESOURCES } from '../../services/resources';
import { API_PREFIX } from '../../utils/injection-tokens';
import {
  Organization,
  OrganizationResponse,
  OrganizationsResponse,
} from './model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  API_PREFIX = inject(API_PREFIX);
  http = inject(HttpClient);

  getOrganizations(): Observable<Organization[]> {
    const url = `${this.API_PREFIX}${RESOURCES.ORGANIZATIONS.organizations}`;

    return this.http
      .get<OrganizationsResponse>(url)
      .pipe(
        map(response =>
          response.organizations.map(org => this.mapOrganization(org)),
        ),
      );
  }

  changeOrganizationName(organizationId: string, name: string) {
    const url = `${this.API_PREFIX}${RESOURCES.ORGANIZATIONS.organization(
      organizationId,
    )}`;

    const body = {
      name,
    };
    return this.http.patch<{ name: string }>(url, body);
  }

  createOrganization(
    name: string,
    is_internal: boolean,
  ): Observable<Organization> {
    const url = `${this.API_PREFIX}${RESOURCES.ORGANIZATIONS.organizations}`;

    const body = {
      name,
      is_internal,
    };

    return this.http
      .post<OrganizationResponse>(url, body)
      .pipe(map(org => this.mapOrganization(org)));
  }

  private mapOrganization(org: OrganizationResponse): Organization {
    return {
      members: org.members_count,
      id: org.id,
      name: org.name,
      isInternal: org.is_internal,
    };
  }
}
