import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { trackingActions } from '../actions/tracking.actions';
import { TrackingService } from '../services/tracking.service';

@Injectable()
export class TrackingEffects {
  track$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(trackingActions.track),
        tap(action => {
          const copy = cloneDeep(action);
          this.trackingService.track(copy.trackingEvent, copy.payload);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private trackingService: TrackingService,
  ) {}
}
