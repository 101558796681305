import { createFeature, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { OrganizationsState } from './model';
import { organizationsActions } from './organizations.actions';

export const organizationsInitialState: OrganizationsState = [];

export const organizationsReducer = createReducer(
  organizationsInitialState,

  on(
    organizationsActions.loadOrganizationsSuccess,
    (state, { organizations }) => organizations,
  ),

  on(
    organizationsActions.changeOrganizationNameSuccess,
    (state, { name, organizationId }) =>
      state.map(organization =>
        organization.id === organizationId
          ? { ...organization, name }
          : organization,
      ),
  ),

  on(
    organizationsActions.increaseOrganizationMemberCounter,
    (state, { organizationId }) => {
      const clone = cloneDeep(state);
      const organization = clone.find(org => org.id === organizationId);
      if (organization) {
        organization.members += 1;
      }

      return clone;
    },
  ),

  on(
    organizationsActions.decreaseOrganizationMemberCounter,
    (state, { organizationId }) => {
      const clone = cloneDeep(state);
      const organization = clone.find(org => org.id === organizationId);
      if (organization) {
        organization.members -= 1;
      }

      return clone;
    },
  ),
);

export const organizationsFeature = createFeature({
  name: 'organizations',
  reducer: organizationsReducer,
});
