import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isEmpty, values } from 'lodash';
import { Observable, filter, fromEvent, tap, withLatestFrom } from 'rxjs';

export function isValidJson(json: string) {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
}

export function checkObjectNotEmpty<T>(obj: T): boolean {
  const empty = values(obj).some(value => value === undefined) || isEmpty(obj);

  return !empty;
}

export function leaveApplication$(changesDetected: Observable<boolean>) {
  const destroyRef = inject(DestroyRef);

  return fromEvent(window, 'beforeunload').pipe(
    withLatestFrom(changesDetected),
    filter(([, changesDetected]) => changesDetected),
    tap(([event]) => event.preventDefault()),
    takeUntilDestroyed(destroyRef),
  );
}
