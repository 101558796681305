<app-dialog-wrapper>
  <ng-container title>
    {{ data.title | translate }}
  </ng-container>

  <ng-container subheader>
    <span
      class="description"
      [innerHTML]="data.description | translate: data.values"
    ></span>
  </ng-container>

  <ng-container actions>
    <app-button
      data-cy="cancel-dialog"
      type="secondary"
      [text]="data.cancelButtonText ?? 'Rewrite.Cancel'"
      (click)="cancel()"
    />
    <app-button
      data-cy="confirm-dialog"
      type="primary"
      [text]="data.submitButtonText"
      (click)="confirm()"
    />
  </ng-container>
</app-dialog-wrapper>
