import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { userFeature } from '../user/user.reducer';
import { promptsActions } from './prompts.actions';
import { PromptsService } from './prompts.service';

@Injectable()
export class PromptsEffects {
  loadPrompts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(promptsActions.loadPrompts),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([, organizationId]) =>
        this.promptsService.getPrompts(organizationId).pipe(
          map(prompts => promptsActions.loadPromptsSuccess({ prompts })),
          catchError(() => of(promptsActions.loadPromptsFailure())),
        ),
      ),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private promptsService: PromptsService,
  ) {}
}
