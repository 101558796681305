import { createFeature, createReducer, on } from '@ngrx/store';
import { Prompt } from './models';
import { promptsActions } from './prompts.actions';

export const promptsInitialState: Prompt[] = [];

export const promptsReducer = createReducer(
  promptsInitialState,
  on(promptsActions.loadPromptsSuccess, (state, { prompts }) => prompts),

  on(promptsActions.deletePromptSuccess, (state, { promptId }) =>
    deletePrompt(state, promptId),
  ),

  on(promptsActions.clearAllPrompts, () => []),
);

function deletePrompt(prompts: Prompt[], promptId: string): Prompt[] {
  return prompts.filter(item => item.id !== promptId);
}

export const promptsFeature = createFeature({
  name: 'prompts',
  reducer: promptsReducer,
});
