import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SystemEffects } from './system.effects';
import { SystemService } from './system.service';

@NgModule({
  imports: [EffectsModule.forFeature([SystemEffects])],
  providers: [SystemService],
})
export class SystemStoreModule {}
