import * as tslib_1 from "tslib";
import { SentryError } from '@sentry/utils';
/** Regular expression used to parse a Dsn. */
var DSN_REGEX = /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+))?@)([\w\.-]+)(?::(\d+))?\/(.+)/;
/** Error message */
var ERROR_MESSAGE = 'Invalid Dsn';
/** The Sentry Dsn, identifying a Sentry instance and project. */
var Dsn = /** @class */function () {
  /** Creates a new Dsn component */
  function Dsn(from) {
    if (typeof from === 'string') {
      this._fromString(from);
    } else {
      this._fromComponents(from);
    }
    this._validate();
  }
  /**
   * Renders the string representation of this Dsn.
   *
   * By default, this will render the public representation without the password
   * component. To get the deprecated private _representation, set `withPassword`
   * to true.
   *
   * @param withPassword When set to true, the password will be included.
   */
  Dsn.prototype.toString = function (withPassword) {
    if (withPassword === void 0) {
      withPassword = false;
    }
    // tslint:disable-next-line:no-this-assignment
    var _a = this,
      host = _a.host,
      path = _a.path,
      pass = _a.pass,
      port = _a.port,
      projectId = _a.projectId,
      protocol = _a.protocol,
      user = _a.user;
    return protocol + "://" + user + (withPassword && pass ? ":" + pass : '') + ("@" + host + (port ? ":" + port : '') + "/" + (path ? path + "/" : path) + projectId);
  };
  /** Parses a string into this Dsn. */
  Dsn.prototype._fromString = function (str) {
    var match = DSN_REGEX.exec(str);
    if (!match) {
      throw new SentryError(ERROR_MESSAGE);
    }
    var _a = tslib_1.__read(match.slice(1), 6),
      protocol = _a[0],
      user = _a[1],
      _b = _a[2],
      pass = _b === void 0 ? '' : _b,
      host = _a[3],
      _c = _a[4],
      port = _c === void 0 ? '' : _c,
      lastPath = _a[5];
    var path = '';
    var projectId = lastPath;
    var split = projectId.split('/');
    if (split.length > 1) {
      path = split.slice(0, -1).join('/');
      projectId = split.pop();
    }
    this._fromComponents({
      host: host,
      pass: pass,
      path: path,
      projectId: projectId,
      port: port,
      protocol: protocol,
      user: user
    });
  };
  /** Maps Dsn components into this instance. */
  Dsn.prototype._fromComponents = function (components) {
    this.protocol = components.protocol;
    this.user = components.user;
    this.pass = components.pass || '';
    this.host = components.host;
    this.port = components.port || '';
    this.path = components.path || '';
    this.projectId = components.projectId;
  };
  /** Validates this Dsn and throws on error. */
  Dsn.prototype._validate = function () {
    var _this = this;
    ['protocol', 'user', 'host', 'projectId'].forEach(function (component) {
      if (!_this[component]) {
        throw new SentryError(ERROR_MESSAGE);
      }
    });
    if (this.protocol !== 'http' && this.protocol !== 'https') {
      throw new SentryError(ERROR_MESSAGE);
    }
    if (this.port && isNaN(parseInt(this.port, 10))) {
      throw new SentryError(ERROR_MESSAGE);
    }
  };
  return Dsn;
}();
export { Dsn };
