<button
  type="button"
  class="main-button fxrow fxcentercenter"
  [ngClass]="type"
  [disabled]="disabled"
  [attr.data-cy]="dataCy"
>
  @if (icon) {
    <mat-icon class="margin-r-s" [svgIcon]="icon"></mat-icon>
  }
  <span>{{ text | translate }}</span>
</button>
