import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PromptsEffects } from './prompts.effects';
import { promptsFeature } from './prompts.reducer';
import { PromptsService } from './prompts.service';

@NgModule({
  imports: [
    StoreModule.forFeature(promptsFeature),
    EffectsModule.forFeature([PromptsEffects]),
  ],
  providers: [PromptsService],
})
export class PromptsStoreModule {}
