import { UserRole } from '../+state/user/models';
import { NavLink, RioPage } from '../models/rio-pages';

export function getDefaultRoute(userRole: UserRole): string {
  return getPagesForRole(userRole)[0]?.route;
}

export function hasAccessToPromptEngineer(userRole: UserRole): boolean {
  return getPagesForRole(userRole).some(
    route => route === promptEngineeringPage,
  );
}

export function getPagesForRole(userRole: UserRole): RioPage[] {
  return rioPages[userRole] || [];
}

// Organization profile tabs
export const apiSettingsTab: NavLink = {
  name: 'API Settings',
  link: '/organization-profile/api-settings',
};

export const membersTab: NavLink = {
  name: 'Members',
  link: '/organization-profile/members',
};

export const organizationTabs: { [key in UserRole]: NavLink[] } = {
  support: [apiSettingsTab, membersTab],
  super_admin: [apiSettingsTab, membersTab],
  admin: [apiSettingsTab, membersTab],
  prompt_engineer: [membersTab],
  editor: [membersTab],
};

// header pages
export const adminSettingsPage: RioPage = {
  name: 'Settings',
  route: apiSettingsTab.link,
};

export const memberSettingsPage: RioPage = {
  name: 'Settings',
  route: membersTab.link,
};

export const promptEngineeringPage: RioPage = {
  name: 'Prompt-Engineering',
  route: '/admin-panel',
};

export const rewritingPage: RioPage = {
  name: 'Rewriting',
  route: '',
};

const rioPages: { [key in UserRole]: RioPage[] } = {
  support: [adminSettingsPage, promptEngineeringPage, rewritingPage],
  super_admin: [adminSettingsPage, promptEngineeringPage, rewritingPage],
  admin: [adminSettingsPage, promptEngineeringPage, rewritingPage],
  prompt_engineer: [promptEngineeringPage, rewritingPage, memberSettingsPage],
  editor: [rewritingPage, memberSettingsPage],
};
