import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Category, PromptCategory } from './models';

export const categoriesActions = createActionGroup({
  source: 'Categories',
  events: {
    'Load Categories': emptyProps(),
    'Load Categories Success': props<{
      categories: PromptCategory[];
    }>(),
    'Load Categories Failure': emptyProps(),

    'Create Category': props<{ name: string }>(),
    'Create Category Success': props<{ category: PromptCategory }>(),
    'Create Category Failure': emptyProps(),

    'Edit Category': props<{ category: Category }>(),
    'Edit Category Success': props<{ category: Category }>(),
    'Edit Category Failure': emptyProps(),

    'Delete Category': props<{ categoryId: string }>(),
    'Delete Category Success': props<{ categoryId: string }>(),
    'Delete Category Failure': emptyProps(),

    'Clear All Categories': emptyProps(),
  },
});
