import { MatTableDataSource } from '@angular/material/table';

// We need changing strings to lowercase to sort alphabetically
// Material table sort is case sensitive, so it will sort uppercase letters first eg. A, B, C, a, b, c
// While we want to sort it as a, A, b, B, c, C
export function sortAlphabetically<T>(dataSource: MatTableDataSource<T>) {
  dataSource.sortingDataAccessor = (data: T, sortHeaderId: string): string =>
    typeof data[sortHeaderId] === 'string'
      ? data[sortHeaderId].toLocaleLowerCase()
      : data[sortHeaderId];

  return dataSource;
}
