import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { InputDialogData } from 'rio-models';
import { WhiteSpacesValidator } from '../../models/validators';
import { ButtonComponent } from '../buttons/button/button.component';
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogWrapperComponent,
    ButtonComponent,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDialogComponent implements OnInit {
  dialogRef = inject(MatDialogRef);
  data: InputDialogData = inject(MAT_DIALOG_DATA);

  name = new FormControl('', [
    Validators.required,
    Validators.minLength(3),
    this.nameExistValidator(),
    WhiteSpacesValidator,
  ]);

  ngOnInit() {
    this.name.setValue(this.data.inputValue || '');
  }

  confirm(): void {
    this.dialogRef.close(this.name.value);
  }

  cancel(): void {
    this.dialogRef.close('');
  }

  nameExistValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isNameExists = this.data.values.includes(control.value);
      return isNameExists ? { nameExist: true } : null;
    };
  }
}
