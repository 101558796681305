import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CategoriesEffects } from './categories.effects';
import { categoriesFeature } from './categories.reducer';
import { CategoriesService } from './categories.service';

@NgModule({
  imports: [
    StoreModule.forFeature(categoriesFeature),
    EffectsModule.forFeature([CategoriesEffects]),
  ],
  providers: [CategoriesService],
})
export class CategoriesStoreModule {}
