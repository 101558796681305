import {
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { localStorageActions } from 'local-storage';
import { TutorialDialogComponent } from 'rio-ui';

@Injectable({ providedIn: 'root' })
export class TutorialService {
  store = inject(Store);
  document = inject(DOCUMENT);
  overlay = inject(Overlay);

  element: HTMLElement;
  overlayRef: OverlayRef;

  showTutorialDialog(highlightedElementId: string) {
    this.element = this.document.getElementById(highlightedElementId);
    this.element.classList.add('highlighted');

    this.overlayRef = this.overlay.create({ hasBackdrop: true });

    const tutorialDialog = new ComponentPortal(TutorialDialogComponent);
    const componentRef = this.overlayRef.attach(tutorialDialog);

    const origin: FlexibleConnectedPositionStrategyOrigin = this.element;
    const strategy: PositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ])
      .withDefaultOffsetX(-80);

    this.overlayRef.updatePositionStrategy(strategy);

    componentRef.instance.closeDialog.subscribe(() => {
      this.closeTutorialDialog();
    });
  }

  closeTutorialDialog() {
    this.overlayRef.dispose();

    this.element.classList.remove('highlighted');

    this.saveInLocalStorage();
  }

  private saveInLocalStorage() {
    this.store.dispatch(
      localStorageActions.setLocalStorageItem({
        key: 'TUTORIAL_DONE',
        value: 'done',
      }),
    );
  }
}
