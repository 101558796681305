import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '../components/dropdown/dropdown.component';

@Pipe({
  name: 'valueToName',
  standalone: true,
})
export class ValueToNamePipe implements PipeTransform {
  transform(value: string, roles: DropdownItem[]): string {
    return roles.find(role => role.value === value)?.name;
  }
}
