import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s ease-in-out', style({ opacity: 1 })),
  ]),
]);

export const fadeOutAnimation = trigger('fadeOutAnimation', [
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.5s ease-in-out', style({ opacity: 0 })),
  ]),
]);

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.5s ease-in-out', style({ opacity: 0 })),
  ]),
]);

export const fadeInDelay = (state: string, delay: string) =>
  trigger('fadeInDelay', [
    transition(state, [
      style({ opacity: 0 }),
      animate(`0.2s ${delay}`, style({ opacity: 1 })),
    ]),
  ]);

export const smoothHeightGrowAnimation = trigger('grow', [
  transition('void <=> *', []),
  transition(
    '* <=> *',
    [
      style({ height: '{{startHeight}}px', opacity: 0 }),
      animate('.5s ease-in-out'),
    ],
    {
      params: { startHeight: 0 },
    },
  ),
]);

export const slideDownAnimation = trigger('slideDown', [
  transition(':enter', [style({ height: 0 }), animate('.5s ease-in-out')]),
]);
