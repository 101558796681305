import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { adminSettingsPage, getDefaultRoute } from '../../sources/rio-pages';
import { categoriesActions } from '../categories/categories.actions';
import { promptsActions } from '../prompts/prompts.actions';
import { userActions } from '../user/user.actions';
import { selectUserRole } from '../user/user.selectors';
import { routerActions } from './router.actions';

@Injectable()
export class RouterEffects {
  navigateToPrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPrompt),
        tap(({ id }) => {
          this.router.navigate(['/admin-panel/prompts', id]);
        }),
      ),
    { dispatch: false },
  );

  navigateToPromptsTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPromptsTable),
        tap(() => {
          this.router.navigate(['/admin-panel/prompts']);
        }),
      ),
    { dispatch: false },
  );

  navigateToAPISettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToAPISettings),
        tap(() => {
          this.router.navigate([adminSettingsPage.route]);
        }),
      ),
    { dispatch: false },
  );

  headerNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.headerNavigation),
        tap(({ page }) => {
          this.router.navigate([page.route]);
        }),
      ),
    { dispatch: false },
  );

  initialNavigationToDefaultRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.initialNavigationToDefaultRoute),
      filter(() => this.location.path() === ''),
      map(() => routerActions.navigateToDefaultRoute()),
    ),
  );

  navigateToDefaultRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToDefaultRoute),
        concatLatestFrom(() => [this.store.select(selectUserRole)]),
        map(([, role]) => getDefaultRoute(role)),
        tap(route => {
          this.router.navigate([route]);
        }),
      ),
    { dispatch: false },
  );

  navigateToNoOrganizationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToNoOrganizationPage),
        tap(() => {
          this.router.navigate(['no-organization']);
        }),
      ),
    { dispatch: false },
  );

  selectOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selectOrganization),
      map(() => this.router.url),
      filter(url => url === '/' || url.includes('/admin-panel')),
      mergeMap(() => [
        categoriesActions.loadCategories(),
        promptsActions.loadPrompts(),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private location: Location,
  ) {}
}
