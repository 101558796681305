import { createFeature, createReducer, on } from '@ngrx/store';
import { categoriesActions } from './categories.actions';
import { Category, PromptCategory } from './models';

export const categoriesInitialState: PromptCategory[] = [];

export const categoriesReducer = createReducer(
  categoriesInitialState,

  on(
    categoriesActions.loadCategoriesSuccess,
    (state, { categories }) => categories,
  ),

  on(categoriesActions.clearAllCategories, () => []),

  on(categoriesActions.createCategorySuccess, (state, { category }) => [
    ...state,
    category,
  ]),

  on(categoriesActions.editCategorySuccess, (state, { category }) =>
    updateCategory(category, state),
  ),

  on(categoriesActions.deleteCategorySuccess, (state, { categoryId }) =>
    deleteCategory(categoryId, state),
  ),
);

function updateCategory(
  category: Category,
  categories: PromptCategory[],
): PromptCategory[] {
  return categories.map(item =>
    item.id === category.id ? { ...item, name: category.name } : item,
  );
}

function deleteCategory(
  categoryId: string,
  categories: PromptCategory[],
): PromptCategory[] {
  return categories.filter(category => category.id !== categoryId);
}

export const categoriesFeature = createFeature({
  name: 'categories',
  reducer: categoriesReducer,
});
