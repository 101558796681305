import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OrganizationsEffects } from './organizations.effects';
import { organizationsFeature } from './organizations.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(organizationsFeature),
    EffectsModule.forFeature([OrganizationsEffects]),
  ],
})
export class OrganizationsStoreModule {}
