import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectHasUserPromptEngineerRole } from '../+state/user/user.selectors';

export const promptEngineerRoleGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectHasUserPromptEngineerRole).pipe(
    map(isPromptEngineer => {
      if (isPromptEngineer) {
        return true;
      } else {
        return router.createUrlTree(['/']);
      }
    }),
  );
};
