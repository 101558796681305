import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TrackingEvent, trackingActions } from 'rio-tracking';
import { map } from 'rxjs';
import { routerActions } from '../router.actions';
import { HeaderTabUsedPayload } from './models';

@Injectable()
export class RouterTrackingEffects {
  headerTabUsed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.headerNavigation),
      map(({ page }) => {
        const payload: HeaderTabUsedPayload = {
          name: page.name,
        };

        return trackingActions.track({
          trackingEvent: TrackingEvent.Navigation.HeaderTabUsed,
          payload,
        });
      }),
    ),
  );

  noOrganizationPageVisited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.navigateToNoOrganizationPage),
      map(() =>
        trackingActions.track({
          trackingEvent: TrackingEvent.System.NoOrganizationPageVisited,
        }),
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}
