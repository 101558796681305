import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SingleToastService, StackedToastService } from 'toasts';
import { toastActions } from './toast.actions';

@Injectable()
export class ToastEffects {
  showStackedToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toastActions.showStackedToast),
        tap(({ text, translationValues }) =>
          this.stackedToastService.showToast(text, translationValues),
        ),
      ),
    { dispatch: false },
  );

  showSingleToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toastActions.showSingleToast),
        tap(({ text, toastType, translationValues }) =>
          this.singleToastService.showToast(text, toastType, translationValues),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private singleToastService: SingleToastService,
    private stackedToastService: StackedToastService,
  ) {}
}
