import { createSelector } from '@ngrx/store';
import { userFeature } from '../user/user.reducer';
import { selectUserRole } from '../user/user.selectors';
import { organizationsFeature } from './organizations.reducer';

export const selectCurrentOrganization = createSelector(
  organizationsFeature.selectOrganizationsState,
  userFeature.selectCurrentOrganizationId,
  (organizations, currentOrganizationId) => {
    return organizations.find(org => org.id === currentOrganizationId);
  },
);

export const selectOrganizationIsInternal = createSelector(
  selectCurrentOrganization,
  currentOrganization => currentOrganization?.isInternal,
);

export const selectOrganizationName = createSelector(
  selectCurrentOrganization,
  currentOrganization => currentOrganization?.name ?? '',
);

export const selectCanEditOrganizationName = createSelector(
  selectUserRole,
  role => {
    return role === 'admin' || role === 'super_admin' || role === 'support';
  },
);
