import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TrackingEvent, trackingActions } from 'rio-tracking';
import { map } from 'rxjs';
import { userActions } from '../user.actions';
import { userTrackingActions } from './user-tracking.actions';

@Injectable()
export class UserTrackingEffects {
  organizationSwitched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selectOrganization),
      map(() =>
        trackingActions.track({
          trackingEvent: TrackingEvent.System.OrganizationSwitched,
        }),
      ),
    ),
  );

  userProfileEditOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userTrackingActions.openUserProfileDialog),
      map(() =>
        trackingActions.track({
          trackingEvent: TrackingEvent.System.UserProfileEditOpened,
        }),
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}
