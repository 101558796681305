import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SkeletonComponent } from 'ui-core';

@Component({
  selector: 'app-prompt-output-skeleton',
  standalone: true,
  imports: [SkeletonComponent],
  templateUrl: './prompt-output-skeleton.component.html',
  styleUrl: './prompt-output-skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptOutputSkeletonComponent {
  protected readonly Array = Array;
}
